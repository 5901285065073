<template>
	<header>
		<AppTheme />
	</header>

	<main>
		<AppAvatar />
		<AppSocial class="wrap" />
		<AppIntro />
		<AppProjects />
		<AppGitHub />
		<AppClients />
		<AppCookie ref="cookie" />
	</main>

	<footer>
		<p>
			&copy; Sascha Künstler {{ currentDate.getFullYear() }}.
			<AppCookieButton @click="$refs.cookie.active = true" />
		</p>
		<AppSocial />
	</footer>
</template>

<script>
import {defineAsyncComponent} from 'vue';

export default {
	name: 'App',
	components: {
		AppAvatar: defineAsyncComponent(() => import('@/components/AppAvatar')),
		AppClients: defineAsyncComponent(() => import('@/components/AppClients')),
		AppCookie: defineAsyncComponent(() => import('@/components/AppCookie')),
		AppCookieButton: defineAsyncComponent(() => import('@/components/AppCookieButton')),
		AppGitHub: defineAsyncComponent(() => import('@/components/AppGitHub')),
		AppIntro: defineAsyncComponent(() => import('@/components/AppIntro')),
		AppProjects: defineAsyncComponent(() => import('@/components/AppProjects')),
		AppSocial: defineAsyncComponent(() => import('@/components/AppSocial')),
		AppTheme: defineAsyncComponent(() => import('@/components/AppTheme'))
	},
	data() {
		return {
			currentDate: new Date,
		};
	}
};
</script>

<style lang="scss">
@import 'scss/layout';
@import 'scss/animations';

/* stylelint-disable selector-max-id */
#app {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	padding: var(--space-20) var(--space-5);
	min-height: 100%;
}
</style>
