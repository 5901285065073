import {createApp} from 'vue';
import App from './App.vue';
import VueGtag from 'vue-gtag-next';
import './registerServiceWorker';
import Bitchify from 'bitchify';

createApp(App)
	.use(VueGtag, {
		property: {
			id: 'G-D56K2LDW27'
		},
		isEnabled: false
	})
	.mount('#app');

new Bitchify();
